import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import Signuplogo from './Signuplogo';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Url from '../Url'
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';


const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [done, setDone] = useState(false);

  let navigate = useNavigate()
  const doLogin = () => {
    if (phone == '' && password == '') {
      setError('Please enter valid phone or password');
    }
    else {
      if (phone == '' || phone.length != 10 || isNaN(phone) == true) {
        setError("Please enter phone number")
      }
      else if (password == '') {
        setError("Please enter valid password")
      }
      else {
        setError('')
        //API CALL HERE//
        loginapi()
      }
    }

  }
  const loginapi = () => {
    setLoader(true)
    const FormData = require('form-data');
    let data = new FormData();
    data.append('phone', phone);
    data.append('password', password);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/loginuser/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setLoader(false)
        console.log(JSON.stringify(response.data));
        let resp = response.data.success.toString();
        if (resp == "Success") {
          let role = response.data.designation.toString()
          let myuser = JSON.stringify(response.data)
          localStorage.setItem('user', myuser)
          if (role == 'user') {
            navigate('/userdashboard')
          }
          else if (role == 'admin') {
            navigate('/admindashboard')
          }
          else {
            localStorage.clear()
            setError("Something went wrong! Please try again later")
          }
        }
        else {
          setLoader(false)
          setError("Something went wrong! Please try again later")
        }
      })
      .catch((error) => {
        setLoader(false)
        setError("Something went wrong! Please try again later")
        console.log(error);
      });
  }

  const sucmsg = <Alert key='success' variant='success'>
    <p>REGISTRATION COMPLETED SUCCESSFULLY</p>
  </Alert>
  const loadmsg = <center>  <Spinner animation="border" variant="secondary" /></center>

  return (
    <div className='login-container'>
      <Card className='login-card-style'>

        <Card.Body>
          <Signuplogo />
          <Card.Title className='txt'>Login for Sample Answer Collection</Card.Title>
          <hr />
          <br />
          <Form>
            {error.length > 0 && <Alert key='danger' variant='danger'>
              {error}
            </Alert>}
            {done && sucmsg}
            {loader && loadmsg}
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-phone"></i>

              </InputGroup.Text>
              <Form.Control
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => {
                  setPhone(phone => e.target.value)
                }}
                aria-label="Phonenumber"
                aria-describedby="basic-addon1"></Form.Control>

            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-lock"></i></InputGroup.Text>
              <Form.Control
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(password => e.target.value)
                }}
                aria-label="Password"
                aria-describedby="basic-addon1"
                type="password"
              />
            </InputGroup>

            <center>
              <Button variant="primary" className='btn' type="button" onClick={doLogin}>
                LOGIN
              </Button>
              <br />
            </center>
            <center><p className="info">Not a member? <Link className='login-txt' to='/register'><span >Register now</span></Link></p></center>
          </Form>
        </Card.Body>
      </Card>







    </div>


  )
}

export default Login