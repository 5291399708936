import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Url from '../Url'
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';



const Register = () => {
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [designation, setDesignation] = useState('user');
  const [experience, setExperience] = useState('');
  const [switches, setSwitches] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState('Select language');
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [done, setDone] = useState(false);

  let navigate = useNavigate()
  const doRegister = () => {
    if (name == '' && age == '' && gender == '' && phone == '' && designation == '' && experience == '' && switches == '' && password == '' && language == 'Select language') {
      setError('Please enter valid input');

    }
    else {
      if (name == '') {
        setError('Please enter a valid name');
      }
      else if (age == '' || age.length != 2 || isNaN(age) == true) {
        setError('Please enter valid age');
      }
      else if (gender == '') {
        setError('Please select your gender');
      }
      else if (phone == '' || phone.length != 10 || isNaN(phone) == true) {
        setError('Please enter valid phone number');
      }
      else if (designation == '') {
        setError('Please enter your designation');
      }
      else if (experience == '') {
        setError('Please enter your experience');
      }
      else if (switches == '') {
        setError('Please enter your job switch');
      }
      else if (password == '') {
        setError('Please enter a valid password');
      }
      else if (language == 'Select language') {
        setError('Please select your preferred language');
      }
      else {
        setError('');
        registeruser()
      }
    }
  }

  const registeruser = () => {
    setLoader(true)
    const FormData = require('form-data');
    let data = new FormData();
    data.append('name', name);
    data.append('age', age);
    data.append('gender', gender);
    data.append('phone', phone);
    data.append('designation', designation);
    data.append('exp_year', experience);
    data.append('switches', switches);
    data.append('password', password);
    data.append('language', language);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/reguser/',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        let resp = response.data.success.toString()
        // alert(resp)
        setLoader(false)
        if (resp.indexOf("User registered with id") !== -1) {
          setError('')
          setDone(true)
          setName('')
          setAge('')
          setGender('')
          setPhone('')
          setDesignation('user')
          setExperience('')
          setSwitches('')
          setPassword('')
          setTimeout(() => {
            navigate("/login")
          }, 3000)
        }
        else if (resp.indexOf("Duplicate") !== -1) {
          setError('Duplicate identity!')
          setDone(false)
          alert("Duplicate name or phone!")
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
        setError('Something went wrong! Please try again later.')
      });

  }

  const sucmsg = <Alert key='success' variant='success'>
    <p>REGISTRATION COMPLETED SUCCESSFULLY</p>
  </Alert>
  const loadmsg = <center>  <Spinner animation="border" variant="secondary" /></center>


  return (
    <div className='login-container'>
      <Card className='register-card'>

        <Card.Body>
          <Card.Title className='txt'>Registration Form</Card.Title>
          <hr />
          <Form>
            {error.length > 0 && <Alert key='danger' variant='danger'>
              {error}
            </Alert>}
            {done && sucmsg}
            {loader && loadmsg}
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-user"></i>

              </InputGroup.Text>
              <Form.Control
                placeholder="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                aria-label="Name"
                aria-describedby="basic-addon1"></Form.Control>

            </InputGroup>
            <InputGroup className="mb-3" controlId="formBasicEmail">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-1"></i>

              </InputGroup.Text>
              <Form.Control type="text"
                placeholder="Enter age"
                value={age}
                onChange={(e) => {
                  setAge(e.target.value);
                }}

              />
            </InputGroup>
            <Row>
              <Col>
                <Form.Label>Gender</Form.Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col><input type="radio"
                className="input-element"
                value={"female"}
                onClick={(e) => {
                  setGender(e.target.value);
                }}
              /> &nbsp;<span className='radio-text' > female</span>
              </Col>
              <Col>
                <input type="radio"
                  className="input-element"
                  value={"male"}
                  onClick={(e) => {
                    setGender(e.target.value);
                  }}
                /> &nbsp;<span className='radio-text' > male</span>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>

            </Row>


            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-phone"></i>

              </InputGroup.Text>
              <Form.Control
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                aria-label="Phonenumber"
                aria-describedby="basic-addon1"></Form.Control>

            </InputGroup>
            <InputGroup className="mb-3" controlId="formBasicEmail">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-user"></i>

              </InputGroup.Text>
              <Form.Control type="text" placeholder="Enter designation"
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
                readOnly={true}
              />
            </InputGroup>
            <InputGroup className="mb-3" controlId="formBasicEmail">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-1"></i>

              </InputGroup.Text>
              <Form.Control type="text" placeholder="Enter year of experience"
                value={experience}
                onChange={(e) => {
                  setExperience(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3" controlId="formBasicEmail">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-1"></i>

              </InputGroup.Text>
              <Form.Control type="text" placeholder="Enter job switches"
                value={switches}
                onChange={(e) => {
                  setSwitches(e.target.value);
                }}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><i class="fa-solid fa-lock"></i></InputGroup.Text>
              <Form.Control
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                aria-label="Password"
                type="password"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <DropdownButton id="dropdown-basic-button" title={language}>
              <Dropdown.Item onClick={() => { setLanguage('english') }}>English</Dropdown.Item>
              <Dropdown.Item onClick={() => { setLanguage('hindi') }}>Hindi</Dropdown.Item>

            </DropdownButton>
            <br />
            <center>
              <Button variant="primary" className='btn' type="button" onClick={doRegister} >
                Register
              </Button>
            </center>
            <center><p className="info">Click here to <Link className='login-txt' to='/login'>LOGIN</Link> if you already have an account.</p></center>
          </Form>
        </Card.Body>
      </Card>





    </div>


  )
}

export default Register