import React, { useState, useEffect } from 'react'
import Header from './Header'
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Footer from './Footer';
import axios from 'axios';
import Url from '../Url'
import { Link } from 'react-router-dom';


const Admindashboard = () => {
  let u = JSON.parse(localStorage.getItem('user'))

  const [dashboarddata, setDashboarddata] = useState({})

  useEffect(() => {
    console.log('admindashboard data', u)
    getDashboardCount()
  }, [])

  const getDashboardCount = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/dashboard/admin/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("dashboard data count:\n", JSON.stringify(response.data));
        if (response.data.success == 'Success') {
          setDashboarddata(response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header userinfo={u} />

      <div className='dashboard-container'>
        <Row className='card-style'>

          <Col>
            <Card style={{ width: '100%' }}>
              <Card.Body>
                <h1>{dashboarddata?.user_count}</h1>
                <Card.Title>Users</Card.Title>
                <Button variant="secondary">
                  <Link style={linkStyle} to="/userlist">View</Link>
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: '100%' }}>
              <Card.Body>
                <h1>{dashboarddata?.questions_count}</h1>
                <Card.Title>Questions</Card.Title>
                <Button variant="secondary">
                  <Link style={linkStyle} to="/questionlist">View</Link>
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card style={{ width: '100%' }}>
              <Card.Body>
                <h1>{dashboarddata?.user_answer_count}</h1>
                <Card.Title>Answers</Card.Title>
                <Button variant="secondary">
                  <Link style={linkStyle} to="/answerlist">View</Link>
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '100%' }}>
              <Card.Body>
                <h1>{dashboarddata?.user_completed_ninety}</h1>
                <Card.Title>Users completed exam</Card.Title>

              </Card.Body>
            </Card>
          </Col>

          <Col></Col>
          <Col></Col>

        </Row>

        <Footer />
      </div>
    </>

  )
}

export default Admindashboard

const linkStyle = {
  textDecoration: 'none',
  color: 'white'
}