import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, Alert } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import Url from '../Url'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';




const Answerlist = () => {
  let u = JSON.parse(localStorage.getItem('user'))
  const [answerlist, setAnswerlist] = useState([])
  const [userlist, setUserlist] = useState([])
  const [curruser, setCurruser] = useState('')

  useEffect(() => {
    console.log('admin answerlist data', u)
    getUsersListData()
  }, [])

  const getAnswerListData = (uid) => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('user_id', uid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/answers/get_answer/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("Answer List Data:\n", response.data);
        setAnswerlist(answerlist => response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getUsersListData = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('designation', 'user');

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/userlist/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("User List Data:\n", response.data);
        setUserlist(userlist => response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const selectUser = (u) => {
    getAnswerListData(u.id)
    setCurruser(u.name)
  }
  const exportData = () => {
    downloadcsv(answerlist)
  }
  const downloadcsv = (f) => {
    let edata = [...f]
    console.log('final exp------>', edata, "\n" + f)
    var csvdata = [];
    var headingdata = ["Id", "Sl_no", "Question", "Score"]
    csvdata.push(headingdata)
    if (edata.length > 0) {
      edata.map((item, index) => {
        var tmp = [index + 1, item.sl_no, item.question.replaceAll(",", " "), item.score]
        csvdata.push(tmp)
      })
    }

    let csvContent = "data:text/csv;charset=unicode utf-8,"
      + csvdata.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Answer Data - " + curruser + ".csv");


    document.body.appendChild(link);
    link.click();

  }


  return (
    <>
      <Header userinfo={u} />

      <div style={pageView}>
        <Row style={rowStyle}>
          <Card className='userlist-card' style={{ width: '99%' }}>
            <h4>List of ANSWERS {curruser != '' && 'for '}<span style={{ color: 'green' }}>{curruser != '' && curruser}</span>
              <DropdownButton className='dropdownStyle' id="dropdown-basic-button" title="Select User">
                {userlist?.map((item1, index1) => {
                  return <Dropdown.Item key={index1} onClick={() => selectUser(item1)}>{item1.name}</Dropdown.Item>
                })}
              </DropdownButton>
            </h4>
          </Card>
        </Row>
        <Row style={rowStyle}>
          <Col>
            {curruser != '' ? answerlist?.length > 0 ? <Table striped bordered>
              <thead>
                <tr><td>  <Button className='btn' variant="Dark" onClick={exportData}>Export Data</Button> </td></tr>
                <tr style={headings}>
                  <th>Srl no.</th>
                  <th>Question</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {answerlist?.length > 0 ? answerlist.map((item, index) => {
                  return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.question}</td>
                    <td>{item.score}</td>
                  </tr>
                }) : null}
              </tbody>
            </Table> : <center>No Answers found</center> : <center>Select User to view answers</center>}

          </Col>
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default Answerlist

const pageView = {
  minHeight: '80vh',
  padding: 50
}

const rowStyle = {
  marginBottom: 20
}

const headings = {
  padding: 20
}
const colorWhite = {
  color: 'white',
}
const newBtn = {
  marginTop: 5,
  color: 'green',
  float: 'right',
  fontSize: 18,
  width: 110,
  borderRadius: 4,
  borderColor: 'white',
  backgroundColor: 'white',
  boxShadow: 0
}
const inputText = {
  height: 40,
  paddingTop: 3,
  fontSize: 14
}
const fieldTitle = {
  fontWeight: 500,
  fontSize: 14,
  color: 'black',
  marginBottom: '4%',
}
const modalTitle = {
  fontSize: 22,
  fontWeight: '600'
}
const error = {
  textAlign: 'right',
  color: 'red',
  marginTop: '-4%',
  marginBottom: '4%',
  fontSize: 11
}
const addButton = {
  backgroundColor: 'green',
  borderWidth: 1,
  borderColor: 'green',
  width: 150
}