import React, { useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { Link, useNavigate } from 'react-router-dom';


const Logout = () => {
    let navigate = useNavigate();
    useEffect(() => {
        clearStorage()
    }, [])
    const clearStorage = () => {
        localStorage.removeItem('user')
        localStorage.clear()
        navigate('/login')
    }
    return (
        <div>
            <center>
                <p>Logging out...</p>
                <div className="loader">  <Spinner animation="border" variant="dark" /></div>
            </center>
        </div>
    )
}

export default Logout