import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Header from './Header';
import Button from 'react-bootstrap/Button';
import Footer from './Footer';
import axios from 'axios'
import Url from '../Url'
import Questionlist from './Questionlist';


const Userquestionlist = () => {
    const [questionlist, setQuestionlist] = useState([])
    let u = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        getquestions()
    }, [])
    const getquestions = () => {
        const FormData = require('form-data');
        let data = new FormData();
        data.append('key', 'mypassword');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: Url.baseUrl + '/questions/list/',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setQuestionlist(response.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    return (
        <>
            <Header userinfo={u} />
            <div>
                <Row>
                    <Col>
                        <Card className='userlist-card' style={{ width: '100%' }}>

                            <Card.Body>

                                <h2 className='text-center'>QUESTION LIST</h2>
                                <div className='t-body'>
                                    <Table striped bordered hover>
                                        <thead>

                                            <tr>
                                                <th>Sl.No</th>

                                                <th>Questions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {questionlist.length > 0 && questionlist.map((item, index) => {
                                                return <tr>
                                                    <td>{item.sl_no}</td>
                                                    <td>{item.question}</td>


                                                </tr>
                                            })}



                                        </tbody>

                                    </Table>
                                </div>

                            </Card.Body>
                        </Card>



                    </Col>
                </Row>
                <Footer />
            </div>
        </>
    )
}

export default Userquestionlist