import React, { useEffect, useState } from 'react';
import Header from './Header';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Footer from './Footer';
import axios from 'axios'
import Url from '../Url'
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';


let nn = 0;
let ans = []
let ques = []
let disableanswers = false
let answered = 0;
let exists = 0;

const Exam = () => {
  let u = JSON.parse(localStorage.getItem('user'))
  const [sl_no, setSl_no] = useState(0)
  const [question, setQuestion] = useState('loading...')
  const [answer, setAnswer] = useState(0)
  let navigate = useNavigate()
  const getslnum = () => {
    disableanswers = true
    let min = Math.ceil(0);
    let max = Math.floor((ques.length) - 1);
    let loc = Math.floor(Math.random() * (max - min + 1)) + min;
    let randomInteger = ques[loc]
    ques.splice(loc, 1)


    return randomInteger
  }
  useEffect(() => {
    setques()
    loadserial()
    getAnswercount()
    let sl_nom = getslnum()
    getquestion(sl_nom)
  }, [])

  const getquestion = (n) => {
    nn = n
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('sl_no', n);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/questions/get_question/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.id.length > 0) {
          setQuestion(u.language == 'english' ? response.data.question : response.data.question_hindi)
          ans.push(n)
          disableanswers = false;
          //  setQuestion(response.data.sl_no)
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const sendAnswer = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('user_id', u.id);
    data.append('user_name', u.name);
    data.append('question', question);
    data.append('score', answer);
    data.append('sl_no', nn);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/answers/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        $("input[type=radio]").prop('checked', false);
        getAnswercount()
        let sl_nom = getslnum()
        getquestion(sl_nom)
        setAnswer(0)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getAnswercount = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('user_id', u.id);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/answers/get_count/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("Answer count :")
        console.log(JSON.stringify(response.data));
        let c = parseInt(response?.data?.count)
        answered = c
        if (c >= 90) {
          alert("You have already answered all questions ")
          navigate('/logout')
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const check_answer = (sl_no) => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('sl_no', sl_no);
    data.append('userid', u.id);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/questions/check_question/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        let n = parseInt(response?.data?.success)
        exists = n
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const loadserial = () => {
    ans = []
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('user_id', u.id);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/answers/get_answer_ids/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        ans = []
        let d = response.data;
        d.map((item, index) => {
          ans.push(parseInt(item?.sl_no))
        })
        console.log('ans -')
        console.log(ans);
        resetques()
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const setques = () => {
    ques = [];
    for (let i = 1; i <= 90; i++) {
      ques.push(i)
    }
  }
  const resetques = () => {
    const difference =
      ques.filter((element) => !ans.includes(element));

    console.log(difference);
    ques = difference;
  }
  return (
    <>
      <Header userinfo={u} />
      <div className='exam-container'>
        <center>
          <Card className="exam-card">
            <h5 style={{ textAlign: 'right' }}>{u.language == 'english' ? 'Answered' : u.language == 'hindi' ? 'कुल उत्तर' : ''} {answered}/90</h5>
            <h2 className='text-center'>{u.language == 'english' ? 'Question' : u.language == 'hindi' ? 'प्रश्न' : ''}</h2>

            <p> <span className='sl-no'>{nn} </span>
              {question}
            </p>
            <hr />
            <div id='answer'>
              <Alert key="dark" className="aler" variant="dark">
                <label>
                  <input type="radio" id='rad1' className='rad' onClick={() => {
                    $('.rad').prop('checked', false);
                    setAnswer(0)
                    $('#rad1').prop('checked', true);
                  }} />
                  &nbsp; {u.language == 'english' ? 'Strongly disagree' : u.language == 'hindi' ? 'दृढ़तापूर्वक असहमत' : ''}
                  <br />
                </label>
              </Alert>
              <Alert key="dark" className="aler" variant="dark">
                <label>
                  <input type="radio" id='rad2' className='rad' onClick={() => {
                    $('.rad').prop('checked', false);
                    setAnswer(1)
                    $('#rad2').prop('checked', true);
                  }} />
                  &nbsp; {u.language == 'english' ? 'Somewhat disagree' : u.language == 'hindi' ? 'कुछ हद तक असहमत' : ''}
                  <br />
                </label>
              </Alert>
              <Alert key="dark" className="aler" variant="dark">
                <label>
                  <input type="radio" id='rad3' className='rad' onClick={() => {
                    $('.rad').prop('checked', false);
                    setAnswer(2)
                    $('#rad3').prop('checked', true);
                  }} />
                  &nbsp; {u.language == 'english' ? 'Agree' : u.language == 'hindi' ? 'सहमत' : ''}
                  <br />
                </label>
              </Alert>
              <Alert key="dark" className="aler" variant="dark">
                <label>
                  <input type="radio" id='rad4' className='rad' onClick={() => {
                    $('.rad').prop('checked', false);
                    setAnswer(3)
                    $('#rad4').prop('checked', true);
                  }} />
                  &nbsp; {u.language == 'english' ? 'Somewhat agree' : u.language == 'hindi' ? 'थोड़ा सहमत' : ''}
                  <br />
                </label>
              </Alert>
              <Alert key="dark" className="aler" variant="dark">
                <label>
                  <input type="radio" id='rad5' className='rad' onClick={() => {
                    $('.rad').prop('checked', false);
                    setAnswer(4)
                    $('#rad5').prop('checked', true);
                  }} />
                  &nbsp; {u.language == 'english' ? 'Strongly agree' : u.language == 'hindi' ? 'दृढ़तापूर्वक सहमत' : ''}
                  <br />
                </label>
              </Alert>

            </div>


            <Card.Body>


              <Button className='btn' variant="Dark" disabled={disableanswers} onClick={sendAnswer}>
                {u.language == 'english' ? 'Submit answer' : u.language == 'hindi' ? 'उत्तर सबमिट करें' : ''}
              </Button>
            </Card.Body>
          </Card>

        </center>



        <Footer />
      </div>
    </>
  )
}

export default Exam