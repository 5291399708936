import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Form, Alert } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import Url from '../Url'

let u = JSON.parse(localStorage.getItem('user'))

const Questionlist = () => {

  const [questionlist, setQuestionlist] = useState([])
  const [modalo, setModalo] = useState(false)
  const [serial, setSerial] = useState('')
  const [alertserial, setAlertserial] = useState(false)
  const [category, setCategory] = useState('')
  const [alertcategory, setAlertcategory] = useState(false)
  const [question, setQuestion] = useState('')
  const [alertquestion, setAlertquestion] = useState(false)
  const [btnloading, setBtnloading] = useState(false)
  const [added, setAdded] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [deletebox, setDeletebox] = useState(false)
  const [currid, setCurrid] = useState('')

  useEffect(() => {
    console.log('admin questionlist data', u)
    getQuestionListData()
  }, [])

  useEffect(() => {
    getQuestionListData()
  }, [added, deleted])

  const openModal = () => {
    setModalo(!modalo)
  }

  const handleClose = () => {
    setModalo(false);
    resetFields()
  }

  const setAcalert = () => {
    setAdded(false)
  }

  const setDealert = () => {
    setDeleted(false)
  }

  const resetFields = () => {
    setSerial('')
    setCategory('')
    setQuestion('')
    setAlertserial(false)
    setAlertcategory('')
    setAlertquestion(false)
  }

  const getQuestionListData = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/questions/list/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("Question List Data:\n", response.data);
        setQuestionlist(questionlist => response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addQuestionFunc = () => {
    if (serial == '' && question == '' && category == '') {
      setAlertserial(true)
      setAlertcategory(true)
      setAlertquestion(true)
    }
    else {
      if (serial == '') {
        setAlertserial(true)
      }
      if (category == '') {
        setAlertcategory(true)
      }
      if (question == '') {
        setAlertquestion(true)
      }
      else {
        setBtnloading(true)
        //API Call for adding Questions
        const FormData = require('form-data');
        let data = new FormData();
        data.append('sl_no', serial);
        data.append('category', category);
        data.append('question', question);

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: Url.baseUrl + '/questions/',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            console.log("Question Added:\n", response.data);
            if (response.data.success != 'Duplicate data') {
              setAdded(true)
              resetFields()
              setModalo()
              setBtnloading(false)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  const deleteQuestion = (qid) => {
    setDeletebox(true)
    console.log("deleting question ", qid)
    setCurrid(currid => qid)
  }

  const deleteQuestionFunc = () => {
    setDeletebox(false)

    //API Call for adding Questions
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('id', currid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/questions/delete/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("Question Deleted:\n", response.data);
        if (response.data.success == 'question deleted successfully') {
          setDeleted(true)
          setDeletebox(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <Modal show={deletebox} className="deleteModal">
        <center>
          <Modal.Body>
            <b>Are you sure you want to delete the selected question?</b><br />
            <p style={{ fontSize: 13, marginTop: 10 }}>
              Note: Any associated <i>answers</i> will also be Deleted</p>
          </Modal.Body>
        </center>
        <Modal.Footer>
          <Button className="cancelBtn" onClick={() => {
            setDeletebox(false)
          }}>Cancel</Button>
          <Button variant="info" style={addButton} onClick={deleteQuestionFunc}>
            <i class="fa fa-trash" style={colorWhite} ></i> &nbsp;
            <span style={colorWhite}>Delete</span>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="useraddModal" scrollable show={modalo} onHide={handleClose}>
        <Modal.Header style={{ borderBottomWidth: 0 }}>
          <span style={modalTitle}>Add Question</span>
          <a onClick={handleClose}><i className='fa fa-close'></i></a>
        </Modal.Header>

        <Modal.Body style={{ borderBottomWidth: 0 }}>

          <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
              {alertserial ? <small style={error}>
                Enter valid serial no.
              </small> : <span style={fieldTitle}>Serial no.<span className="orangeColor"> * </span></span>}
              <Form.Control defaultValue={serial} onChange={e => setSerial(e.target.value)} style={inputText} type="text" placeholder="Enter serial no." />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicName">
              {alertcategory ? <small style={error}>
                Enter valid category
              </small> : <span style={fieldTitle}>Category<span className="orangeColor"> * </span></span>}
              <Form.Control defaultValue={category} onChange={e => setCategory(e.target.value)} style={inputText} type="text" placeholder="Enter category name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {alertquestion ? <small style={{ textAlign: 'right', color: 'red', marginTop: '-4%', fontSize: 11 }}>
                Enter question body
              </small> : <span style={fieldTitle}>Question<span className="orangeColor"> * </span></span>}
              <Form.Control defaultValue={question} multiple={true} onChange={e => setQuestion(e.target.value)} style={inputText} type="text" placeholder="Enter your qustion" />
            </Form.Group>
          </Form>

        </Modal.Body>

        <Modal.Footer>
          <Button className="cancelBtn" onClick={handleClose}>Cancel</Button>
          <Button variant="info" style={addButton} onClick={addQuestionFunc}>
            {btnloading ? <i className="fa fa-loading" style={colorWhite}></i> : <>
              <i className='fa fa-add' style={colorWhite}></i>&nbsp;
              <span style={colorWhite}>Add Question</span></>}
          </Button>

        </Modal.Footer>
      </Modal>
      <Header userinfo={u} />
      <div style={pageView}>

        {added && <Alert variant="success" onClose={() => setAcalert(false)} dismissible>Question has been added successfully</Alert>}

        {deleted && <Alert variant="danger" onClose={() => setDealert(false)} dismissible>Question has been deleted successfully</Alert>}

        <Row style={rowStyle}>
          <Card className='userlist-card' style={{ width: '99%' }}>
            <h4>List of QUESTIONS
              <button style={newBtn} onClick={openModal}><b>+Add new</b></button>
            </h4>
          </Card>
        </Row>
        <Row style={rowStyle}>
          <Col>
            {questionlist?.length > 0 ? <Table striped bordered>
              <thead>
                <tr style={headings}>
                  <th>Sl.No</th>
                  <th>Category</th>
                  <th>Question</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {questionlist?.length > 0 ? questionlist.map((item, index) => {
                  return <tr key={index}>
                    <td>{item.sl_no}</td>
                    <td>{item.category}</td>
                    <td>{item.question}</td>
                    <td><a onClick={() => deleteQuestion(item.id)}><i className='fa fa-trash'></i></a></td>
                  </tr>
                }) : null}
              </tbody>
            </Table> : <center>No Questions found</center>}

          </Col>
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default Questionlist

const pageView = {
  minHeight: '80vh',
  padding: 50
}

const rowStyle = {
  marginBottom: 20
}

const headings = {
  padding: 20
}
const colorWhite = {
  color: 'white',
}
const newBtn = {
  marginTop: 5,
  color: 'green',
  float: 'right',
  fontSize: 18,
  width: 110,
  borderRadius: 4,
  borderColor: 'white',
  backgroundColor: 'white',
  boxShadow: 0
}
const inputText = {
  height: 40,
  paddingTop: 3,
  fontSize: 14
}
const fieldTitle = {
  fontWeight: 500,
  fontSize: 14,
  color: 'black',
  marginBottom: '4%',
}
const modalTitle = {
  fontSize: 22,
  fontWeight: '600'
}
const error = {
  textAlign: 'right',
  color: 'red',
  marginTop: '-4%',
  marginBottom: '4%',
  fontSize: 11
}
const addButton = {
  backgroundColor: 'green',
  borderWidth: 1,
  borderColor: 'green',
  width: 150
}