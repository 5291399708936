import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import Url from '../Url'


const Userlist = () => {
  let u = JSON.parse(localStorage.getItem('user'))
  const [userlist, setUserlist] = useState([])

  useEffect(() => {
    console.log('admin userlist data', u)
    getUserListData()
  }, [])

  const getUserListData = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('designation', 'user');

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/userlist/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("User List Data:\n", response.data);
        setUserlist(userlist => response.data)
        console.log("Setting user list", userlist)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <Header userinfo={u} />
      <div style={pageView}>
        <Row style={rowStyle}>
          <Card className='userlist-card' style={{ width: '99%' }}>
            <h4>List of USERS</h4>
          </Card>
        </Row>
        <Row style={rowStyle}>
          <Col>
            {userlist?.length > 0 ? <Table striped bordered >
              <thead>
                <tr style={headings}>
                  <th>Sl.No</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Phone</th>
                  <th>Years of experience</th>
                  <th>No. of Switches</th>
                </tr>
              </thead>
              <tbody>
                {userlist?.length > 0 ? userlist.map((item, index) => {
                  return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.age}</td>
                    <td>{item.gender}</td>
                    <td>{item.phone}</td>
                    <td>{item.exp_year}</td>
                    <td>{item.switches}</td>
                  </tr>
                }) : null}
              </tbody>
            </Table> : <center>No Users found</center>}

          </Col>
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default Userlist

const pageView = {
  minHeight: '80vh',
  padding: 50
}

const rowStyle = {
  marginBottom: 20
}

const headings = {
  padding: 20
}