import React from 'react'
import '../App.css';
import { Card, Button } from 'react-bootstrap'
import Header from './Header'
const Mycard = () => {
    return (
        <>
            <Header />
            <div className="App">
                <header className="App-header">
                    <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src="https://via.placeholder.com/150" />
                        <Card.Body>
                            <Card.Title style={txtstyle}>Card Title</Card.Title>
                            <Card.Text style={txtstyle}>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text>
                            <Button variant="primary">Go somewhere</Button>
                        </Card.Body>
                    </Card>
                </header>
            </div>
        </>
    )
}
const txtstyle = {
    color: 'black'
}
export default Mycard