import React, { useEffect } from 'react'
import '../App.css';
import { Card, Button, Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import {

    NavLink

} from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';


const Header = ({ userinfo }) => {

    useEffect(() => {
        console.log('user header', userinfo)

    }, [])

    return (
        <Navbar bg="dark" expand="lg">
            <Container>
                <Navbar.Brand>
                    {userinfo?.success == "Success" && <Link to={userinfo.designation == 'admin' ? '/admindashboard' : '/userdashboard'} className="p-style-black">HR PILOT TEST</Link>}
                    {userinfo?.success !== "Success" && <Link to='/login' className="p-style-black">HR PILOT TEST</Link>}

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ 'color': 'white' }} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {userinfo?.success != "Success" && <Nav.Link><Link to="/login" className="p-style-black">Home</Link></Nav.Link>}
                        {userinfo?.success != "Success" && <Nav.Link ><Link to="/login" className="p-style-black">Login</Link></Nav.Link>}
                        {userinfo?.success == "Success" && <Nav.Link ><Link to={userinfo.designation == 'admin' ? '/admindashboard' : '/userdashboard'} className="p-style-black">Dashboard</Link></Nav.Link>}

                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end" >
                    {userinfo?.success == "Success" && <Navbar.Text style={{ color: 'white' }}>
                        Signed in as: <a style={{ color: 'white' }} href="#login">{userinfo?.name}</a>
                    </Navbar.Text>}
                    {userinfo?.success == "Success" && <Nav.Link ><Link to="/logout" className="p-style-black">&nbsp; &nbsp; &nbsp;<i title="Logout" class="fa-solid fa-right-from-bracket logout-icon"></i></Link></Nav.Link>}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
const txtstyle = {
    color: 'black'
}
export default Header