import React from 'react'

const Footer = () => {
  return (
    <div className='footer-div'>
      <p>&copy; Heimdall 2024 </p>
    </div>
  )
}

export default Footer