import React from 'react'
import '../App.css';
import { Card, Button, Alert } from 'react-bootstrap'
import Header from './Header'
const ErrorPage = () => {
    const doReload = () => {
        window.location.href = window.location.href
    }
    return (
        <>
            <Header />
            <div className="App">
                <header className="App-header">
                    <Card style={{ width: '18rem' }}>

                        <Card.Body>
                            <Card.Title style={txtstyle}>Page Not Found</Card.Title>
                            <Card.Text style={txtstyle}>
                                <Alert key={1} variant={'warning'}>
                                    The page you requested is not found !!
                                </Alert>
                            </Card.Text>
                            <Button variant="primary" onClick={doReload}>RETRY NOW!</Button>
                        </Card.Body>
                    </Card>
                </header>
            </div>
        </>
    )
}
const txtstyle = {
    color: 'black'
}
export default ErrorPage