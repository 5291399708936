import React, { useEffect, useState } from 'react';
import Header from './Header';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Footer from './Footer';
import axios from 'axios'
import Url from '../Url'
import { Link, useNavigate } from 'react-router-dom';




const Userdashboard = () => {
  let u = JSON.parse(localStorage.getItem('user'))

  const [questions, setQuestions] = useState(0)
  const [answers, setAnswers] = useState(0)
  let navigate = useNavigate()
  useEffect(() => {
    console.log('userdashboard data', u)
    getUserDashboard()
    getAnswercount()
  }, [])

  const getUserDashboard = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('user_id', u.id);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/dashboard/user/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.success.toString() == "Success") {
          setQuestions(response.data.questions_count)
          setAnswers(response.data.user_answer_count)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getAnswercount = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('key', 'mypassword');
    data.append('user_id', u.id);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Url.baseUrl + '/answers/get_count/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("Answer count :")
        console.log(JSON.stringify(response.data));
        let c = parseInt(response?.data?.count)
        if (c >= 90) {
          alert("You have already answered all questions ")
          navigate('/logout')
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Header userinfo={u} />
      <div className='dashboard-container'>
        <Row className='card-style'>
          <Col sm={12} xs={12} md={3} lg={3}>
            <Card style={{ width: '100%' }}>

              <Card.Body>
                <h1>{90 - answers} {u.language == 'english' ? 'answers remaining' : u.language == 'hindi' ? 'उत्तर अधूरे' : ''}</h1>
                <Card.Title>{u.language == 'english' ? 'Take Exam' : u.language == 'hindi' ? 'परीक्षा लें' : ''}</Card.Title>
                <Button variant="secondary"><Link className='link-style' to="/exam">{u.language == 'english' ? 'Start Exam' : u.language == 'hindi' ? 'परीक्षा प्रारंभ करें' : ''}</Link></Button>

              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} xs={12} md={3} lg={3}>
            <Card style={{ width: '100%' }}>

              <Card.Body>
                <h1>{questions}</h1>
                <Card.Title>{u.language == 'english' ? 'Question' : u.language == 'hindi' ? 'प्रश्न' : ''}</Card.Title>
                {/*  <Button variant="secondary"><Link className='link-style' to="/userquestionlist">View</Link></Button> */}

              </Card.Body>
            </Card>


          </Col>
          <Col sm={12} xs={12} md={3} lg={3}>
            <Card style={{ width: '100%' }}>

              <Card.Body>
                <h1>{answers}/90</h1>
                <Card.Title>{u.language == 'english' ? 'Total Answers' : u.language == 'hindi' ? 'कुल उत्तर' : ''}</Card.Title>
                {/*      <Button variant="secondary"><Link className='link-style' to="/useranswerlist">View</Link></Button>
*/}
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} xs={12} md={3} lg={3}> </Col>
          <Col sm={12} xs={12} md={3} lg={3}></Col>
        </Row>
        <Footer />
      </div>

    </>
  )
}

export default Userdashboard