import React from 'react'

const Signuplogo = () => {
  return (
    <center> 
    <div className='user-icon'>
    <i class="fa-solid fa-user txt-style"></i>
    </div>
    </center>
  )
}

export default Signuplogo