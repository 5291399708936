import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Myhome from './components/Myhome'
import Mycard from './components/Mycard'
import ErrorPage from './components/ErrorPage'
import Login from './components/Login'
import Register from './components/Register';
import Admindashboard from './components/Admindashboard';
import Userdashboard from './components/Userdashboard';
import Userlist from './components/Userlist';
import Questionlist from './components/Questionlist';
import Userquestionlist from './components/Userquestionlist';
import Exam from './components/Exam';
import Answerlist from './components/Answerlist';
import Logout from './components/Logout';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/myhome",
      element: <Myhome />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/mycard",
      element: <Mycard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/logout",
      element: <Logout />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/register",
      element: <Register />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/admindashboard",
      element: <Admindashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/userdashboard",
      element: <Userdashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/userlist",
      element: <Userlist />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/questionlist",
      element: <Questionlist />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/exam",
      element: <Exam />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/answerlist",
      element: <Answerlist />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/userquestionlist",
      element: <Userquestionlist />,
      errorElement: <ErrorPage />,
    },
  ]);
  return (
    <>

      <RouterProvider router={router} />
    </>

  );
}

export default App;
